import { createRouter, createWebHistory } from "vue-router";
import jwtDecode from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "partner",
    component: () => import("../components/partner/HomeView.vue"),
    beforeEnter: (to, from, next) => {
      const decode = jwtDecode(localStorage.getItem("token"));
      if (decode.status_appover === "อนุมัติ") {
        next();
      } else {
        next({ name: "HomeNewPartner" });
      }
    },
  },
  {
    path: "/",
    name: "HomeNewPartner",
    component: () => import("../components/partner/HomeNewView.vue"),
    beforeEnter: (to, from, next) => {
      const decode = jwtDecode(localStorage.getItem("token"));
      if (decode.status_appover === "ยังกรอกข้อมูลไม่ครบ" || decode.status_appover === "รออนุมัติ" || decode.status_appover === "อนุมัติแล้ว") {
        next();
      } else {
        next({ name: "partner" });
      }
    },
  },
  {
    path: "/ContractByID/:id",
    name: "ContractByID",
    component: () => import("../components/partner/contact.vue")
  },
  {
    path: "/Contract/AddContract",
    name: "AddContract",
    component: () => import("../components/contact/AddContact.vue")
  },
  {
    path: "/Contract/",
    name: "Contract",
    component: () => import("../components/contact/ContactView.vue")
  },
  {
    path: "/Dealers",
    name: "Dealers",
    component: () => import("../components/dealers/dealerview.vue")
  },
  {
    path: "/Dealersview",
    name: "Dealersview",
    component: () => import("../components/dealers/dealer.vue")
  },
  {
    path: "/partner/requstproduct",
    name: "requstproduct",
    component: () => import("../components/partner/product/requestproduct.vue")
  },
  {
    path: "/partner/productview",
    name: "productview",
    component: () => import("../components/partner/product/productpartner.vue")
  },
  {
    path: "/partner/shopview",
    name: "shopview",
    component: () => import("../components/partner/shop/shoppartner.vue")
  },
  {
    path: "/partner/one-stop-service",
    name: "one-stop-service",
    component: () => import("../components/partner/tossagun/osService.vue")
  },
  {
    path: "/partner/shop-tg",
    name: "partner-shop-tg",
    component: () => import("../components/partner/tossagun/TableShop.vue"),
  },
  {
    path: "/partner/register",
    name: "register-one-stop",
    component: () => import("../components/partner/tossagun/register/RegisterView.vue"),
  },
  {
    path: "/partner/register/list",
    name: "register-one-stop-list",
    component: () => import("../components/partner/tossagun/register/TableRegister.vue"),
  },
  {
    path: "/partner/requestshop",
    name: "requestshop",
    component: () => import("../components/partner/shop/requestshop.vue")
  },
  {
    path: "/partner/productshop/:id",
    name: "productshop",
    component: () => import("../components/partner/shop/productshop.vue")
  },
  {
    path: "/partner/orderpartnerview",
    name: "orderpartnerview",
    component: () => import("../components/partner/product/order/orderpartnerview.vue")
  },
  {
    path: "/partner/reportpartner/:id",
    name: "reportpartner",
    component: () => import("../components/partner/product/reportpartner.vue")
  },
  {
    path: "/partner/one-stop-service/employee/:id",
    name: "employeeone-stop-service",
    component: () => import("../components/partner/tossagun/osServiceemployee.vue")
  },
  {
    path: "/partner/followupwork",
    name: "followupwork",
    component: () => import("../components/partner/followwork/followorkpartner.vue")
  },
  {
    path: "/partner/followupwork/detail/",
    name: "followupworkdetail",
    component: () => import("../components/partner/followwork/detailwork.vue")
  },
  {
    path: "/partner/productshop/posshop/",
    name: "posshop2",
    component: () => import("../components/partner/shop/posshop.vue")
  },
  {
    path: "/partner/productshop/posshop/:id",
    name: "posshop",
    component: () => import("../components/partner/shop/posshop.vue")
  },
  {
    path: "/partner/productshop/posshop/orderview/:id",
    name: "orderview",
    component: () => import("../components/partner/shop/ordershop.vue")
  },
  {
    path: "/partner/productshop/posshop/report/:id",
    name: "reportposshop",
    component: () => import("../components/partner/shop/reportshoppos.vue")
  },
  {
    path: "/addproducttg",
    name: "addproducttg",
    component: () => import("../components/partner/producttg/addproducttgview.vue")
  },
  {
    path: "/producttg",
    name: "producttg",
    component: () => import('../components/partner/producttg/producttgview.vue')
  },
  {
    path: "/producttg/order",
    name: "producttg-order",
    component: () => import("../components/partner/producttg/OrderProductTG.vue"),
  },
  {
    path: "/editproducttg/:id",
    name: "editproducttg",
    component: () => import('../components/partner/producttg/editproducttgview.vue')
  },
  {
    path: "/partner/productshop/employee/:id",
    name: "employeeshop",
    component: () => import("../components/partner/shop/employeeshop.vue")
  }


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
