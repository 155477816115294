import axios from "axios";

export class Partner {
  #token = localStorage.getItem("token");
  #baseUrl = process.env.VUE_APP_API;
  #baseUrl2 = process.env.VUE_APP_OFFICE;
  #baseUrl3 = process.env.VUE_APP_CONTACT;
  #baseUrl4 = process.env.VUE_APP_CATEGORY;
  #baseUrl5 = process.env.VUE_APP_SHOP;
  constructor() {}

  // รอลบทิ้ง
  async GetUser() {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/user`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async AddUser(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/user/`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async EditUser(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/user/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async DeleteUser(packageId) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/user/${packageId}`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async GetUserbyid(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/user/byid/${id}`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async AddPartner(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/register/`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Sendopt(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/sendotp/${id}`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async Check(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/check/${id}`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async AddIdcard(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/idcard/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async Addfilecompany(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/filecompany/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Addfilecompany2(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/filecompany2/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Addfilecompany3(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/filecompany3/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async Addfilecompany4(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/filecompany4/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async AddLogo(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/logo/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Addcompanyseal(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/companyseal/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Addsignature(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/addsignature/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async Deletesignature(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/deletesignature/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async Getbypartnerid(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/byid/${id}`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async EditPartner(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async AddPartner2(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl2}/partners/register`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async GetContract(packageData) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/v1/main/get-my/${packageData}`,
      //data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async CancleContract(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/HaveplaceNocapital/AddStatus/${id}`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async AccpetContract(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/partner-contract/sign/${id}`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  async PaidContract(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/partner-contract/paid/${id}`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  async EditContract(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/HaveplaceNocapital/AddStatus/${id}`,
      data: packageData,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  //เรียกจังหวัดจังหวัด
  async GetProvince() {
    let data;
    const config = {
      method: "get",
      // headers: {
      //   "token": this.#token,
      // },
      url: `https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  //เรียกอำเภอ
  async GetAmphure(id) {
    let data;
    const config = {
      method: "get",
      // headers: {
      //   "token": this.#token,
      // },
      url: `https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }
  //เรียกตำบล
  async GetTambon(id) {
    let data;
    const config = {
      method: "get",
      // headers: {
      //   "token": this.#token,
      // },
      url: `https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });

    return data;
  }

  //ส่งข้อมูลให้ adimn อนุมัติ
  async SendAdmin(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/partner/WaitForApproval/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ส่งข้อมูลpdpa
  async Sendpdpa(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl3}/partner-contract/create/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ดึงข้อมูลคำร้องขอฝากชายสินค้า by partner
  async GetRequestProduct(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/bypartner/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //เพิ่มคำร้องขอฝากขายสินค้า
  async AddRequestProduct(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //แก้ไขคำร้องขอฝากขายสินค้า
  async EditRequestProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ลบข้อมูลคำร้องขอฝากขายสินค้า
  async DeleteRequestProduct(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปเอกสาร
  async AddImageDocRequestProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/imagedoc/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพคำร้องขอฝากขายสินค้า
  async AddImageRequestProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/image/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพคำร้องขอฝากขายสินค้าย่อย 1
  async AddImageRequestProduct1(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/subimage1/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพคำร้องขอฝากขายสินค้าย่อย 2
  async AddImageRequestProduct2(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/subimage2/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพคำร้องขอฝากขายสินค้าย่อย 3
  async AddImageRequestProduct3(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestproduct/subimage3/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //สินค้า
  //ดึงข้อมูลหมวดหมู่สินค้า
  async GetCategory() {
    let data;
    const config = {
      method: "get",
      url: `${this.#baseUrl5}/product/tossagun/category/all`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ดึงข้อมูลหมวดหมู่ย่อยสินค้า
  async GetSubCategory() {
    let data;
    const config = {
      method: "get",
      url: `${this.#baseUrl5}/product/tossagun/type/all`,
    };

    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    console.log(data);
    return data;
  }

  //ดึงข้อมูลสินค้า by partner
  async GetProduct(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/bypartner/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //แก้ไขสินค้า
  async EditProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ลบสินค้า
  async DeleteProduct(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เปิด-ปิดสินค้า
  async OpenCloseProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/status/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มเอกสาร
  async AddImageDocProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/imagedoc/image/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มเอกสารสินค้า
  async AddImageDocProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/imagedoc/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //เพิ่มรูปภาพสินค้า
  async AddImageProduct(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/image/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพสินค้าย่อย 1

  async AddImageProduct1(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/subimage1/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพสินค้าย่อย 2
  async AddImageProduct2(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/subimage2/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพสินค้าย่อย 3
  async AddImageProduct3(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/product/subimage3/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //คำร้องขอร้านค้า
  //ดึงข้อมูลคำร้องขอเปิดร้านค้า by partner
  async GetRequestShop(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestshop/bypartner/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มคำร้องขอเปิดร้านค้า
  async AddRequestShop(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestshop/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //แก้ไขคำร้องขอเปิดร้านค้า
  async EditRequestShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestshop/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ลบข้อมูลคำร้องขอเปิดร้านค้า
  async DeleteRequestShop(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/requestshop/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ร้านค้า
  //ดึงข้อมูลร้านค้า by id
  async GetShopById(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/byid/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ดึงข้อมูลร้านค้า by partner
  async GetShop(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/bypartner/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มร้านค้า
  async AddShop(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //แก้ไขร้านค้า
  async EditShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ลบร้านค้า
  async DeleteShop(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เปิด-ปิดร้านค้า
  async OpenCloseShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/shop/status/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //สินค้าของร้านค้า
  //เพิ่มสินค้าของร้านค้า
  async AddProductShop(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ดึงข้อมูลสินค้าของร้านค้า by partner
  async GetProductShop(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/byshopid/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //แก้ไขสินค้าของร้านค้า
  async EditProductShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ลบสินค้าของร้านค้า
  async DeleteProductShop(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เปิด-ปิดสินค้าของร้านค้า
  async OpenCloseProductShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/status/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //เพิ่มรูปภาพสินค้าของร้านค้า
  async AddImageProductShop(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/productshop/image/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  // one stop service
  //ดึงข้อมูล one stop service by partner
  async GetOneStopService(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/bypartner/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
  //ดึงข้อมูล one stop service by id
  async GetOneStopServiceById(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/byid/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //สร้าง one stop service
  async AddOneStopService(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //แก้ไข one stop service
  async EditOneStopService(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ดึงพนักงาน ทั้งหมด
  async GetOneStopServiceEmployee(id) {
    let data;
    const config = {
      method: "get",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/employee/shop/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //เพิ่มพนักงาน
  async AddOneStopServiceEmployee(packageData) {
    let data;
    const config = {
      method: "post",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/employee`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //แก้ไขพนักงาน
  async EditOneStopServiceEmployee(packageData, id) {
    let data;
    const config = {
      method: "put",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/onestopservice/employee/${id}`,
      data: packageData,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }

  //ลบพนักงาน
  async DeleteOneStopServiceEmployee(id) {
    let data;
    const config = {
      method: "delete",
      headers: {
        token: this.#token,
      },
      url: `${this.#baseUrl}/employee/${id}`,
    };
    await axios(config)
      .then((result) => {
        if (result) {
          data = result.data;
        }
      })
      .catch((error) => {
        data = error;
      });
    return data;
  }
}
